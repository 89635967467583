import React from 'react'
import ReactDOM from 'react-dom/client'
import { BrowserRouter } from 'react-router-dom'
import { HelmetProvider } from 'react-helmet-async'
import App from './App'
import reportWebVitals from './reportWebVitals'
import { AuthAdminContextProvider } from './context/AdminAuthContext'
import { AuthContextProvider } from './context/AuthContext'
import { CurrencyContextProvider } from './context/CurrencyContext'

const root = ReactDOM.createRoot(document.getElementById('root'))
root.render(
  <AuthAdminContextProvider>
    <AuthContextProvider>
      <CurrencyContextProvider>
        <React.StrictMode>
          <HelmetProvider>
            <BrowserRouter>
              <App />
            </BrowserRouter>
          </HelmetProvider>
        </React.StrictMode>
      </CurrencyContextProvider>
    </AuthContextProvider>
  </AuthAdminContextProvider>
)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
