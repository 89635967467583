import { createContext, useState } from 'react'
import getSymbolFromCurrency from 'currency-symbol-map'

export const CurrencyContext = createContext()

export const CurrencyContextProvider = ({ children }) => {
  const [currency, setCurrency] = useState('£')

  const changeCurrency = (newCurrency) => {
    setCurrency(getSymbolFromCurrency(newCurrency))
  }

  return (
    <CurrencyContext.Provider value={{ currency, changeCurrency }}>
      {children}
    </CurrencyContext.Provider>
  )
}
