import React, { useEffect } from 'react'
import AOS from 'aos'
import 'aos/dist/aos.css'
import { Suspense, useMemo } from 'react'
import Router from './Routes'
import Loader from './components/Loader'
import './App.css'
import { ThemeProvider, createTheme, responsiveFontSizes } from '@mui/material'
import ScrollToTop from './components/ScrollToTop'
import { ToastContainer, Slide } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'
import { SnackbarProvider } from 'notistack'
import { styled } from '@mui/material'
import { AuthContextProvider } from './context/AuthContext'

// alert notification
const StyledSnackbarProvider = styled(SnackbarProvider)`
  &.SnackbarItem-contentRoot {
    background-color: #130146;
    box-shadow: 0px 3px 2px -2px rgba(0, 0, 0, 0.06), 0px 5px 3px -2px rgba(0, 0, 0, 0.02);
    font-weight: 500;
    border-radius: 8px;
    border: 1px solid rgba(255, 255, 255, 0.2);
  }
`

function App() {
  useEffect(() => {
    AOS.init({
      once: true,
    })
    AOS.refresh()
  }, [])
  const theme = useMemo(
    () =>
      createTheme({
        palette: {
          mode: 'dark',
          primary: {
            main: '#D2C3B0',
          },
          secondary: {
            main: '#DF8B3B',
          },
          error: {
            main: '#E11A1A',
          },
        },
        typography: {
          fontFamily: 'Open Sans',
          h1: {
            fontFamily: 'Open Sans',
            fontSize: '48px',
            fontWeight: '800',
            color: '#FFFFFF',
            lineHeight: '1.5',
          },
          h2: {
            fontFamily: 'Open Sans',
            fontSize: '23px',
            fontWeight: '500',
            color: '#DF8B3B',
            lineHeight: '1.3',
          },
          h3: {
            fontFamily: 'Open Sans',
            fontSize: '40px',
            fontWeight: '700',
            color: '#FFFFFF',
            lineHeight: '1.5',
          },
          h4: {
            fontFamily: 'Open Sans',
            fontSize: '17px',
            fontWeight: '700',
            color: '#FFFFFF',
            lineHeight: '1.5',
            textTransform: 'uppercase',
          },
          h5: {
            fontFamily: 'Open Sans',
            fontSize: '32px',
            fontWeight: '500',
            color: '#FFFFFF',
            lineHeight: '1.5',
          },
          h6: {
            fontFamily: 'Open Sans',
            fontSize: '16px',
            fontWeight: '400',
            color: 'rgba(153, 153, 153, 1)',
            lineHeight: '1.5',
          },
          h7: {
            fontFamily: 'Open Sans',
            fontSize: '24px',
            fontWeight: '700',
            color: '#FFF',
            lineHeight: '1.5',
          },
          subtitle1: {
            fontFamily: 'Open Sans',
            fontSize: '18px',
            fontWeight: '400',
            color: '#FFFFFF',
          },
          subtitle2: {
            fontFamily: 'Open Sans',
            fontSize: '14px',
            fontWeight: '400',
            color: '#FFFFFF',
          },
          body1: {
            fontFamily: 'Open Sans',
            fontWeight: '400',
            color: '#FFF',
            lineHeight: '1.5',
          },
        },
        components: {
          MuiButton: {
            styleOverrides: {
              root: {
                borderRadius: '50px',
                backgroundColor: '#DF8B3B',
                color: '#111',
                boxShadow: 'none',
                fontFamily: 'Open Sans',
                fontSize: '16px',
                fontWeight: 600,
                textTransform: 'capitalize',
                padding: '10px 20px',
                lineHeight: '24px',
                height: '50px',
                ':hover': {
                  backgroundColor: '#DF8B3B',
                  boxShadow: 'none',
                },
              },
              text: {
                borderRadius: '50px',
                backgroundColor: 'transparent',
                color: 'white',
                boxShadow: 'none',
                fontFamily: 'Open Sans',
                fontSize: '16px',
                fontWeight: 600,
                textTransform: 'capitalize',
                padding: '10px 20px',
                lineHeight: '24px',
                height: '50px',
                ':hover': {
                  backgroundColor: 'transparent',
                  boxShadow: 'none',
                },
              },
              next: {
                color: 'white',
                boxShadow: '0px 0px 20px 0px rgba(255, 255, 255, 0.08)',
                border: '1px solid rgba(255, 255, 255, 0.40)',
                backgroundColor: '#000',
                height: '60px',
                '&:hover': {
                  backgroundColor: '#000',
                  boxShadow: '0px 0px 20px 0px rgba(255, 255, 255, 0.08)',
                },
                '@media (max-width: 1200px)': {
                  height: '50px',
                },
              },
            },
          },
          MuiDateCalendar: {
            styleOverrides: {
              root: {},
            },
          },
          MuiPaper: {
            styleOverrides: {
              root: {
                backgroundColor: '#121212',
                boxShadow: 'none',
              },
            },
          },
          MuiList: {
            styleOverrides: {
              root: {
                backgroundColor: '#282625',
                border: '1px solid rgba(207, 211, 212, 0.20)',
                borderRadius: '8px',
                padding: '0',
              },
            },
          },
          MuiMenuItem: {
            styleOverrides: {
              root: {
                backgroundColor: '#121212',
                border: '1px solid rgba(255, 255, 255, 0.09)',
              },
            },
          },

          MuiStep: {
            styleOverrides: {
              root: {},
            },
          },
          MuiStepConnector: {
            styleOverrides: {
              root: {
                top: '25px',
                width: '75%',
                left: 'calc(-30% + -30px)',
                right: 'calc(60% + 0px)',
                '& .MuiStepConnector-line': {
                  borderColor: '#DF8B3B',
                  borderWidth: '2px',
                  borderTopStyle: 'dashed',
                },
                '@media (max-width:900px)': {
                  display: 'none',
                },
              },
            },
          },
          MuiSvgIcon: {
            styleOverrides: {
              root: {
                '&.Mui-active': {
                  color: 'rgb(74 48 25)!important',
                },
                '& .MuiStepIcon-text': {
                  fill: '#FFFFFF',
                },
              },
            },
          },
          MuiStepIcon: {
            styleOverrides: {
              root: {
                width: '50px',
                height: '50px',
                color: 'rgb(74 48 25)!important',
                '& .Mui-active': {
                  color: 'rgb(74 48 25)!important',
                },
              },
            },
          },

          MuiInputBase: {
            styleOverrides: {
              root: {
                width: '100%',
                border: '1px solid rgba(255, 255, 255, 0.20)',
                background: 'rgba(255, 255, 255, 0.10)',
                borderRadius: '8px !important',
                fontSize: '16px',
                fontWeight: '400',
                color: 'rgba(255, 255, 255, 0.50)',
                '&:hover': {
                  borderColor: '(255, 255, 255, 0.20)',
                },
                '&:focus': {
                  borderColor: '(255, 255, 255, 0.20)',
                },
              },
            },
          },

          MuiFormLabel: {
            styleOverrides: {
              root: {
                fontSize: '12px',
              },
            },
          },
          MuiCard: {
            styleOverrides: {
              root: {
                border: '1px solid #FFF',
                background: '#090909',
                boxShadow: '0px 0px 88px 4px rgba(223, 139, 59, 0.20)',
                borderRadius: '10px',
                padding: '20px',
                marginBottom: '20px',
              },
            },
          },
          MuiDialog: {
            styleOverrides: {
              backdrop: {
                // backgroundColor: 'white',
                backdropFilter: 'blur(7px)',
              },
              paper: {
                borderRadius: '16px',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                textAlign: 'center',
                flexDirection: 'column',
                maxWidth: '1200px',
              },
            },
          },
          MuiTooltip: {
            styleOverrides: {
              tooltip: {
                backgroundColor: '#FFFFFF',
                color: '#000',
                border: '1px solid rgba(234, 236, 238, 0.6)',
                boxShadow: '0px 0px 14px rgba(0, 0, 0, 0.04)',
              },
              arrow: {
                color: '#FFFFFF',
                '::before': {
                  border: '1px solid rgba(234, 236, 238, 0.6)',
                },
              },
            },
          },
        },
        breakpoints: {
          values: {
            xs: 320,
            sm: 600,
            md: 900,
            lg: 1200,
            xl: 1536,
          },
        },
      }),
    []
  )

  return (
    <>
      <ScrollToTop />
      <ThemeProvider theme={responsiveFontSizes(theme)}>
        <StyledSnackbarProvider
          hideIconVariant
          autoHideDuration={2500}
          anchorOrigin={{
            vertical: 'top',
            horizontal: 'right',
          }}
        >
          <Suspense fallback={<Loader />}>
            <AuthContextProvider>
              <Router />
            </AuthContextProvider>
          </Suspense>
        </StyledSnackbarProvider>
        <ToastContainer transition={Slide} />
      </ThemeProvider>
    </>
  )
}

export default App
