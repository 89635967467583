import React from 'react'
import { Box, CircularProgress } from '@mui/material'
import FormBackground from './FormBackground'

const Loader = () => {
  return (
    <>
      <FormBackground />
      <Box
        sx={{
          position: 'absolute',
          top: '50%',
          left: '50%',
          transform: 'traslate(-50%, -50%)',
          zIndex: 1,
        }}
      >
        <CircularProgress
          sx={{
            color: '#DF8B3B',
          }}
        />
      </Box>
    </>
  )
}

export default Loader
