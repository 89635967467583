import React, { lazy } from 'react'
import { Navigate, useRoutes } from 'react-router-dom'
import { retry } from './utils/CommonFunctions'

const Home = lazy(() => retry(() => import('./pages/Home')))
const QRLink = lazy(() => retry(() => import('./pages/Common/QRLink')))
const ContactUs = lazy(() => retry(() => import('./pages/Common/ContactUs')))
const CountryOfBusiness = lazy(() => retry(() => import('./pages/CountryOfBusiness')))
const BusinessDetails = lazy(() => retry(() => import('./pages/BusinessDetails')))
const PersonalDetails = lazy(() => retry(() => import('./pages/PersonalDetails')))
const OwnerDetails = lazy(() => retry(() => import('./pages/OwnerDetails')))
const BankDetails = lazy(() => retry(() => import('./pages/BankDetails')))
const TermsConditions = lazy(() => retry(() => import('./pages/Common/TermsConditions')))
const TermsAndCondition = lazy(() => retry(() => import('./pages/Common/TermsAndCondition')))
const Register = lazy(() => retry(() => import('./pages/Register/Register')))
const OTP = lazy(() => retry(() => import('./pages/Register/OTP')))
const TerminalDashboard = lazy(() =>
  retry(() => import('./pages/TerminalDashboard/TerminalDashboard'))
)
const NewTerminal = lazy(() => retry(() => import('./pages/TerminalDashboard/NewTerminal')))
const ProfileSetting = lazy(() => retry(() => import('./pages/ProfileSetting')))
const AddNewTerminal = lazy(() => retry(() => import('./pages/TerminalDashboard/AddNewTerminal')))
const RestrictedBusiness = lazy(() => retry(() => import('./pages/Common/RestrictedBusiness')))
const SupportUs = lazy(() => retry(() => import('./pages/Common/SupportUs')))
const Dashboard = lazy(() => retry(() => import('./pages/TerminalDashboard/Dashboard')))
const TermsAndService = lazy(() => retry(() => import('./pages/Common/TermsAndService')))
const PrivacyPolicy = lazy(() => retry(() => import('./pages/Common/PrivacyPolicy')))
const DeleteAccount = lazy(() => retry(() => import('./pages/Common/DeleteAccount')))
const Loader = lazy(() => retry(() => import('./components/Loader')))
const InventoryDashboard = lazy(() => retry(() => import('./pages/InventoryManagement/Dashboard')))
const AddProduct = lazy(() => retry(() => import('./pages/InventoryManagement/AddProduct')))
const InventoryOption = lazy(() =>
  retry(() => import('./pages/InventoryManagement/InventoryOption'))
)
const AddOption = lazy(() => retry(() => import('./pages/InventoryManagement/AddOption')))
const SelectOption = lazy(() => retry(() => import('./pages/InventoryManagement/SelectOption')))
const ValueForOptions = lazy(() =>
  retry(() => import('./pages/InventoryManagement/ValueForOptions'))
)
const Transaction = lazy(() => retry(() => import('./pages/Transaction')))
const Support = lazy(() => retry(() => import('./pages/Common/Support')))
const Faq = lazy(() => retry(() => import('./pages/Common/Faq')))
const UniqualSupportUs = lazy(() => retry(() => import('./pages/Common/UniqualSupportUs')))
const Analytics = lazy(() => retry(() => import('./pages/Analytics/Analytics')))
const AnalyticsFilter = lazy(() => retry(() => import('./pages/Analytics/AnalyticsFilter')))
const PageNotFound = lazy(() => retry(() => import('./pages/Common/PageNotFound')))

const Login = lazy(() => retry(() => import('./pages/Admin/Login')))
const AdminDashboard = lazy(() => retry(() => import('./pages/Admin/Layout/Dashboard')))

// const ProtectedRoute = ({ element }) => {
//   const user = localStorage.getItem('user')

//   return user ? element : <Navigate to="/register" replace={true} />
// }

const AppRoutes = () => {
  const routes = useRoutes([
    {
      path: '/',
      element: <Home />,
      // element: <ProtectedRoute element={<Home />} />,
    },
    {
      path: '/contact-us',
      element: <ContactUs />,
    },
    {
      path: '/terminals',
      element: <TerminalDashboard />,
    },
    {
      path: '/dashboard',
      element: <Dashboard />,
    },
    {
      path: '/profile-setting',
      element: <ProfileSetting />,
    },
    {
      path: '/terminals/create',
      element: <AddNewTerminal />,
    },
    {
      path: '/terminals/edit/:id',
      element: <NewTerminal />,
    },
    {
      path: '/register',
      element: <Register />,
    },
    {
      path: '/register/otp',
      element: <OTP />,
    },
    {
      path: '/country-of-business',
      element: <CountryOfBusiness />,
    },
    {
      path: '/business-details',
      element: <BusinessDetails />,
    },
    {
      path: '/personal-details',
      element: <PersonalDetails />,
    },
    {
      path: '/owner-details',
      element: <OwnerDetails />,
    },
    {
      path: '/bank-details',
      element: <BankDetails />,
    },
    {
      path: '/terms-and-condition',
      element: <TermsAndCondition />,
    },
    {
      path: '/terms-condition',
      element: <TermsConditions />,
    },
    {
      path: '/terms-of-service',
      element: <TermsAndService />,
    },
    {
      path: '/privacy-policy',
      element: <PrivacyPolicy />,
    },
    {
      path: '/delete-account',
      element: <DeleteAccount />,
    },
    {
      path: '/support-us',
      element: <SupportUs />,
    },
    {
      path: '/uniqual-support',
      element: <UniqualSupportUs />,
    },
    {
      path: '/support',
      element: <Support />,
    },
    {
      path: '/inventory-management',
      element: <InventoryDashboard />,
    },
    {
      path: '/inventory-management/add-product',
      element: <AddProduct />,
    },
    {
      path: '/inventory-management/edit-product/:id',
      element: <AddProduct />,
    },
    {
      path: '/inventory-management/option',
      element: <InventoryOption />,
    },
    {
      path: '/inventory-management/add-option',
      element: <AddOption />,
    },
    {
      path: '/inventory-management/select-option',
      element: <SelectOption />,
    },
    {
      path: '/inventory-management/select-option/value',
      element: <ValueForOptions />,
    },
    {
      path: '/restricted-businesses',
      element: <RestrictedBusiness />,
    },
    {
      path: '/transaction',
      element: <Transaction />,
    },
    {
      path: '/analytics',
      element: <Analytics />,
    },
    {
      path: '/analytics/filter',
      element: <AnalyticsFilter />,
    },
    {
      path: '/loader',
      element: <Loader />,
    },
    {
      path: '/link',
      element: <QRLink />,
    },
    {
      path: '/faqs',
      element: <Faq />,
    },
    {
      path: '/admin/login',
      element: <Login />,
    },
    {
      path: '/admin/*',
      element: <AdminDashboard />,
    },
    {
      path: '/*',
      element: <Navigate to="/404" />,
    },
    {
      path: '/404',
      element: <PageNotFound />,
    },
  ])
  return routes
}

export default AppRoutes
