import React from 'react'
import { Box } from '@mui/material'

const FormBackground = () => {
  return (
    <Box
      sx={{
        width: '100vw',
        height: '100vh',
        background:
          'linear-gradient(90deg, #31273A 0%, #943D2A 21%, #DF8B3B 46%, #E9C99A 65%, white 100%)',
        position: 'fixed',
        top: 0,
        left: 0,
        // right: 0,
        // bottom: 0,
        // boxShadow: '0px 0px 0px',
        // filter: 'blur(0px)',
        opacity: 0.1,
      }}
    />
  )
}

export default FormBackground
