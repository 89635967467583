import { createContext, useReducer, useEffect } from 'react'

export const AuthAdminContext = createContext()

export const authReducer = (state, action) => {
  switch (action.type) {
    case 'LOGIN':
      return { user: action.payload }
    case 'REGISTER':
      return { user: action.payload }
    case 'LOGOUT':
      return { user: null }
    default:
      return state
  }
}

export const AuthAdminContextProvider = ({ children }) => {
  const [state, dispatch] = useReducer(authReducer, {
    user: localStorage.getItem('admin'),
  })

  useEffect(() => {
    const user = localStorage.getItem('admin')

    if (user) {
      dispatch({ type: 'LOGIN', payload: user })
    }
  }, [])

  return (
    <AuthAdminContext.Provider value={{ ...state, dispatch }}>{children}</AuthAdminContext.Provider>
  )
}
